<template>
  <BaseModal ref="modal" title="Keuringen aanvragen" max-width="tw-max-w-2xl">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="certificationForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <fieldset class="fieldset-split-range !tw-flex !tw-flex-row !tw-gap-x-2 tw-items-center">
        <legend>
          Facturatie
          <span class="input-error">(verplicht)</span>
        </legend>
        <FormulateInput
          v-model="propertyOwner"
          name="propertyOwner"
          type="select"
          placeholder="Kies een eigenaar"
          :options="propertyOwners"
          :validation="!contact.id ? 'bail|required|completeAddress' : ''"
          :validation-rules="{ completeAddress }"
          :validation-messages="{
            required: 'Facturatiecontact is verplicht.',
            completeAddress: 'Facturatiecontact heeft geen (volledig) adres (vereist: straat, nummer, woonplaats)'
          }"
          outer-class="tw-m-0 tw-w-1/2"
        />
        OF
        <FormulateInput
          v-model="contact"
          name="contact"
          type="autocomplete"
          auto-complete-type="contact"
          placeholder="Selecteer een contact"
          :validation="!propertyOwner.id ? 'bail|required|completeAddress' : ''"
          :validation-rules="{ completeAddress }"
          :validation-messages="{
            required: 'Facturatiecontact is verplicht.',
            completeAddress: 'Facturatiecontact heeft geen (volledig) adres (vereist: straat, nummer, woonplaats)'
          }"
          outer-class="tw-m-0 tw-w-1/2"
        />
      </fieldset>
      <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-my-4" v-if="linkedContacts.length">
        <FormulateInput
          v-model="linkedContact"
          label="Bedrijfscontact"
          name="linkedContact"
          type="select"
          placeholder="Kies een bedrijfscontact"
          :options="linkedContacts"
          :validation="!contact.id ? 'bail|required|completeAddress' : ''"
          :validation-rules="{ completeAddress }"
          :validation-messages="{
            required: 'Facturatiecontact is verplicht.',
            completeAddress: 'Facturatiecontact heeft geen (volledig) adres (vereist: straat, nummer, woonplaats)'
          }"
          outer-class="tw-m-0"
        />
      </div>
      <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-4">
        <FormulateInput
          v-model="heatingType.value"
          name="heating_type"
          type="select"
          label="Verwarmingstype"
          :options="[heatingType]"
          disabled
          error-behavior="live"
          validation="required"
          :validation-messages="{
            required: 'Het kenmerk ‘Verwarmingsketel’ moet ingesteld staan op ‘Gemeenschappelijk’ of ‘Individueel’ om verder te kunnen gaan'
          }"
          :show-required-label="true"
          outer-class="tw-m-0"
        />
        <FormulateInput
          name="transaction_type"
          type="select"
          label="Transactietype"
          :options="[transactionType]"
          :value="transactionType.value"
          disabled
          error-behavior="live"
          validation="required"
          :validation-messages="{
            required: `Keuringen kunnen niet worden aangevraagd voor panden met status '${entity.status_display}'`
          }"
          :show-required-label="true"
          outer-class="tw-m-0"
        />
      </div>
      <FormulateInput
        name="inspection_types"
        type="checkbox"
        label="Keuringen"
        validation="required"
        :show-required-label="true"
        :options="INSPECTION_TYPES"
        :input-class="['tw-my-0']"
        :element-class="['tw-mt-1 tw-grid tw-grid-cols-2 tw-gap-y-1 tw-gap-x-4']"
      />
      <FormulateInput
        v-if="heatingContactRequired"
        name="heating_contact"
        type="text"
        label="Contactpersoon"
        validation="bail|heatingContactIsRequired|max:200,length"
        :validation-rules="{ heatingContactIsRequired }"
        :validation-messages="{
          heatingContactIsRequired: 'Contactpersoon is een verplicht veld',
          max: 'Contactpersoon mag maximaal 200 karakters lang zijn'
        }"
        :show-required-label="heatingContactRequired"
      />
      <FormulateInput
        name="visit_information"
        type="textarea"
        label="Bezoek informatie"
        :value="entity.visit_arrangement"
        validation="required"
        :validation-messages="{
          required: 'Bezoek informatie is een verplicht veld'
        }"
        :show-required-label="true"
      />
      <FormulateInput
        name="extra_information"
        type="textarea"
        label="Extra informatie"
      />
      <template v-if="values.inspection_types && values.inspection_types.includes('6')">
        <FormulateInput
          name="oil_tank_location"
          type="radio"
          label="Stookolietank: locatie"
          :options="{ bovengronds: 'Bovengronds', ondergronds: 'Ondergronds' }"
          value="bovengronds"
        />
        <FormulateInput
          name="oil_tank_access"
          type="radio"
          label="Stookolietank: vrij toegankelijk"
          :options="{ ja: 'Ja', nee: 'Nee' }"
          value="ja"
        />
      </template>

      <FormulateErrors />

      <FormulateInput type="submit" :disabled="isLoading" :outer-class="['tw-float-right']">
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-cart-plus'
          ]"
        />
        Doorsturen
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { getPropertyEnergy, getPropertyOwners } from '@/services/properties'
import { createLog } from '@/services/apiService'
import {
  createCertificationRequest,
  addInspectionType,
  addInvoiceContact,
  confirmCertificationRequest,
  pollCertificationService
} from '@/services/certificationService'
import { getContactLinks } from '@/services/contacts'
import { poll } from '@/utils/helpers'
import { errorModal } from '@/modalMessages'

export default {
  name: 'CertificationOrderModal',
  props: {
    entity: {
      type: Object,
      required: true
    }
  },
  constants: {
    INSPECTION_TYPES: [
      { value: 8, label: 'Elektrische keuring residentieel' },
      { value: 9, label: 'Elektrische keuring niet-residentieel' },
      { value: 2, label: 'EPC residentieel' },
      { value: 3, label: 'EPC klein niet-residentieel' },
      { value: 7, label: 'EPC niet-residentieel' },
      { value: 4, label: 'EPC gemeenschappelijke delen' },
      { value: 5, label: 'Gaskeuring' },
      { value: 6, label: 'Mazouttank keuring' }
    ],
    PROPERTY_STATUSES: {
      2: {
        value: 1,
        label: 'Verkoop'
      },
      10: {
        value: 1,
        label: 'Verkoop'
      },
      3: {
        value: 2,
        label: 'Verhuur'
      },
      11: {
        value: 2,
        label: 'Verhuur'
      }
    },
    HEATING_BOILERS: {
      0: {
        error: 'Toegestane verwarmingstypes zijn gemeenschappelijk en individueel, voeg het juiste verwarmingstype toe aan de pand.'
      },
      1: {
        error: 'Toegestane verwarmingstypes zijn gemeenschappelijk en individueel, voeg het juiste verwarmingstype toe aan de pand.'
      },
      2: {
        value: 1,
        label: 'Gemeenschappelijk',
        error: null
      },
      3: {
        value: 2,
        label: 'Individueel',
        error: null
      }
    },
    STATUS_SUCCESS: 1,
    STATUS_FAILED: 2
  },
  data () {
    return {
      values: {},
      propertyOwner: '',
      contact: {},
      propertyOwners: [],
      transactionType: {},
      heatingType: {},
      linkedContact: {},
      linkedContacts: []
    }
  },
  computed: {
    heatingContactRequired () {
      return this.heatingType.value === '1' && this.values.inspection_types?.some(type => ['2', '3'].includes(type))
    }
  },
  watch: {
    async propertyOwner (owner) {
      if (owner?.id) this.contact = ''
      if (owner?.id && owner.type === 'B') {
        await this.loadLinkedContacts(owner.id)
        if (this.linkedContacts.length) this.linkedContact = this.linkedContacts[0].value
      } else {
        this.linkedContact = {}
        this.linkedContacts = []
      }
    },
    async contact (contact) {
      if (contact?.id) this.propertyOwner = ''
      if (contact?.id && contact.type === 'B') {
        await this.loadLinkedContacts(contact.id)
        if (this.linkedContacts.length) this.linkedContact = this.linkedContacts[0].value
      } else {
        this.linkedContact = {}
        this.linkedContacts = []
      }
    }
  },
  methods: {
    completeAddress ({ value }) {
      return value && value.street && value.number && value.city
    },
    heatingContactIsRequired ({ getFormValues }) {
      const values = getFormValues()
      return (this.heatingContactRequired && values.heating_contact) || !this.heatingContactRequired
    },
    hide () {
      this.$refs.modal.hide()
    },
    async show () {
      await this.init()
      this.$refs.modal.show()
    },
    async loadPropertyEnergy (propertyId) {
      const response = await getPropertyEnergy(propertyId)
      this.heatingType = Object.assign({}, this.heatingType, this.HEATING_BOILERS[response.data?.heating_boiler])
      return response
    },
    async loadPropertyOwners (propertyId) {
      const response = await getPropertyOwners(propertyId)
      this.propertyOwners = response.data?.map(owner => {
        return {
          id: owner?.id, // To prevent the id of option being [object Object]
          label: owner?.display_name,
          value: owner
        }
      })
      return response
    },
    async loadLinkedContacts (contactId) {
      try {
        const response = await getContactLinks(contactId)
        if (response && response.data) {
          console.log(response)
          this.linkedContacts = response.data.results.map(linkedContact => {
            const toRelation = linkedContact?.to_relation
            const type = linkedContact?.type
            return {
              id: toRelation?.id,
              label: toRelation && type ? `${toRelation.display_name} - ${type.name}` : '',
              value: toRelation
            }
          })
        } else {
          this.linkedContacts = []
        }
        return response
      } catch (error) {
        console.error('Error loading linked contacts:', error)
        this.linkedContacts = []
        this.linkedContact = {}
        return null
      }
    },
    async init () {
      const propertyId = this.entity.id
      // If status is 16 (Dossieropbouw) then use the transaction_type set on the property
      const lookupValue = this.entity.status === 16 ? this.entity.transaction_type : this.entity.status
      this.transactionType = this.PROPERTY_STATUSES[lookupValue] || {} // {} In order to handle cases when status is not among the ones included.
      return await Promise.all([this.loadPropertyEnergy(propertyId), this.loadPropertyOwners(propertyId)])
    },
    async submit (values) {
      let startedPolling = false
      try {
        const certification_request_id = await this.handleCertificationRequest(values)
        await this.handleInspectionTypes(certification_request_id, values.inspection_types)
        await this.handleInvoiceContact(certification_request_id, values)
        const jobId = await this.confirmCertification(certification_request_id)
        startedPolling = true
        await poll(jobId, pollCertificationService, 1000)
        const payload = {
          action_id: 6, // Certi+ IAM action id
          content_object_id: this.$route.params.id,
          content_object_class: 'property',
          meta: { certification_request_id }
        }
        const response = await createLog(payload)
        this.hide()
        this.$emit('completed')
        return response
      } catch (error) {
        if (startedPolling) {
          errorModal('Doorsturen naar Certi+ is niet gelukt. Gelieve later nog eens te proberen (15min)')
        }
        this.$formulate.handle(error, 'certificationForm')
        throw error
      }
    },
    async handleCertificationRequest (values) {
      const { id, street, number, box, city, price, type } = this.entity
      const {
        transaction_type,
        heating_type,
        visit_information,
        extra_information,
        oil_tank_location,
        oil_tank_access
      } = values
      const address = box ? `${street} ${number} ${box}` : `${street} ${number}`
      const payload = {
        property_id: id,
        address,
        zip_code: city?.zip_code,
        city: city?.name,
        building_type: [5, 76, 51, 47, 53, 6, 59, 85].includes(type?.id) ? 2 : 1,
        contact: values.heating_contact || '',
        price,
        transaction_type,
        heating_type,
        visit_information,
        extra_information,
        oil_tank_location,
        oil_tank_access
      }
      const response = await createCertificationRequest(payload)
      return response.data?.id
    },
    async handleInspectionTypes (certRequestId, inspectionTypes) {
      const promises = inspectionTypes.map(inspection_type => addInspectionType(certRequestId, { inspection_type }))
      const response = await Promise.all(promises)
      return response.data?.id
    },
    async handleInvoiceContact (certRequestId, { contact, propertyOwner }) {
      const {
        type,
        company_name,
        first_name,
        last_name,
        language,
        phone,
        email,
        mobile,
        vat,
        city,
        zip_code,
        street,
        number
      } = contact || propertyOwner
      const payload = {
        address: `${street} ${number}`,
        zip_code,
        city,
        language,
        email
      }
      if (type === 'P') {
        payload.contact_type = 1
        payload.first_name = first_name
        payload.last_name = last_name
      } else {
        payload.contact_type = 2
        payload.company_name = company_name
        payload.vat = vat
        if (this.linkedContact?.id) {
          payload.first_name = this.linkedContact.first_name
          payload.last_name = this.linkedContact.last_name
        }
      }
      payload.phone = phone || mobile
      const response = await addInvoiceContact(certRequestId, payload)
      return response
    },
    async confirmCertification (certRequestId) {
      const response = await confirmCertificationRequest(certRequestId)
      return response.data?.job_id
    }
  }
}
</script>
