import { certificationClient } from './axiosClients'

export function createCertificationRequest (payload) {
  return certificationClient.post('/api/requests', payload)
}

export function addInspectionType (requestId, payload) {
  return certificationClient.post(`/api/requests/${requestId}/inspections`, payload)
}

export function addInvoiceContact (requestId, payload) {
  return certificationClient.post(`/api/requests/${requestId}/invoicecontact `, payload)
}

export function confirmCertificationRequest (requestId) {
  return certificationClient.post(`/api/requests/${requestId}/confirm`)
}

export function pollCertificationService (jobId) {
  return certificationClient.get(`/api/jobs/${jobId}`)
}
